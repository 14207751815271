.PdfView {
    font-family: sans-serif;
    text-align: center;
}

h4 {
    text-align: center;
    margin: 20px auto;
    
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 10px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
    .react-pdf__Page__canvas {
        width: 100vw;
    }
}

    /*For all pages*/ 
.all-page-container {
    width: 100vw;
    overflow: visible;
    max-height: 100vh;
}